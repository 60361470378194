import React from 'react'



const Terms = () =>  {
    return<div >
        <h1 style={{margin:"40px", paddingTop:"40px", fontSize:"44px"}}>Welcome</h1>
        <div style={{margin:"120px"}}>
            <h2>Code Perception Experiment</h2>
            <p>Dear participants, welcome to the experiment about how developers perceive code and assess code quality. This experiment is conducted by University of Zurich.
            </p>
            <h2>Instructions:</h2>
            <p>
                - This experiment takes approx. 15-20 minutes.
                <br/>
                - We ask you to have a look at presented pieces of code and *estimate* their code quality.
                <br/>
                - Then, we ask you to assess how understandable and readable are some pieces of code.
                <br/>
                - Afterwards, we will ask you several clarification questions and questions about you as a developer.
                <br/>
                We are grateful for your help,
                <br/>
                Pavlina and Alberto
            </p>
            <p>**University of Zurich**</p>
            <h2>Data Handling Policy</h2>
            <p>
                (1) Your answers are anonymized to the best of our capabilities, moreover, the IP address from which you complete the experiment is not saved.
                <br/>
                (2) In this experiment, we also ask you general questions on your experience as a developer; none of these questions will be mandatory. Please do not share personal and/or sensitive information in any open question you will find in the survey. Should this happen, we are going to remove your answer from the final dataset.
                <br/>
                (3) You can withdraw from the experiment at any moment. However, even under your request, we cannot delete your record once you submit your responses due to the aforementioned anonymized data collection.
                <br/>
                (4) This study uses servers hosted at the University of Zurich, Switzerland to run the experiment as well as to archive the data once the study is completed. Therefore, your data is only stored internally and is protected by strict Swiss privacy laws.
                <br/>
                (5) All the data is used for research purposes only; the data will not be sold or privately shared to third parties.
                <br/>
                (6) The data handling is under the responsibility of Pavlína Wurzel Gonçalves (p.goncalves@ifi.uzh.ch) and Alberto Bacchelli (bacchelli@ifi.uzh.ch).
            </p>
            <h2>Data Policy Confirmation Checkbox</h2>
            <p>
                Hereby I confirm, that I have read the data handling policy, I am of legal age and I agree with my participation in the study.
            </p>
            <h2>Treatment conditions</h2>
            <h3>Very short</h3>
            <p>
                In the following part of the experiment, we will show you 5 code snippets. You have very short time to have a look at them. After seeing each of these code snippets, we ask you to assess its code quality. Press "Next" once you are ready to start.
            </p>
            <h3>Scanning time</h3>
            <p>
                In the following part of the experiment, we will show you 5 code snippets. You have limited time to have a look at them. After seeing each of these code snippets, we ask you to assess its code quality. Press "Next" once you are ready to start.
            </p>
            <h3>Control</h3>
            <p>
                In the following part of the experiment, we will show you 5 code snippets. Take as much time as you wish to have a look at them. After seeing each of these code snippets, we ask you to assess its code quality. Press "Next" once you are ready to start.
            </p>
        </div>
    </div>

}

export default Terms